import React, { useState } from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Checkbox } from 'components/Form/Checkbox';
import { Select } from 'components/Form/Select';
import LineHeightText from 'components/LineHeightText';
import { CheckInModal } from 'components/Modal';
import { SearchableListContainer } from 'components/SearchableListContainer';
import Spacer from 'components/Spacer';
import { Table } from 'components/Table';
import { useGetMembershipDivisions } from 'pages/app/AnnualTickets/Members/gql/useGetMembershipDivisions';
import { TitleContainer } from 'pages/track/Contact/style';
import {
  useCheckinRegistration,
  useGetEventRegistrations,
  useUnCheckRegistration,
} from './gql';
import { useGetRegistrationTickets } from './gql/useGetRegistrationTickets';
import { RegistrationsHeader } from './RegistrationsHeader';
import { UpdateRegistration } from './UpdateRegistration';

const ParticipantName = styled.button`
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-family: 'Roboto';
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const FilterContainer = styled.div`
  min-width: 280px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

export const MembershipFilterContainer = styled.div`
  min-width: 250px;
  margin: 5px;
`;

export function getDriverName(data, user) {
  const firstName =
    data
      .find(
        (field) =>
          field.name === 'first_name' || field.name === 'drivers_first_name'
      )
      ?.value.trim() ?? '';
  const middleName =
    data
      .find(
        (field) =>
          field.name === 'middle_name' || field.name === 'drivers_middle_name'
      )
      ?.value.trim() ?? '';
  const lastName =
    data
      .find(
        (field) =>
          field.name === 'last_name' || field.name === 'drivers_last_name'
      )
      ?.value.trim() ?? '';

  if (firstName.length && lastName.length) {
    const driverName = `${lastName ? `${lastName}, ` : ''}${firstName}${
      middleName ? ` ${middleName}` : ''
    }`;
    return driverName;
  } else {
    const userName = `${user.last_name ? `${user.last_name}, ` : ''}${
      user.first_name
    }${user.middle_name ? ` ${user.middle_name}` : ''}`;
    return userName;
  }
}

export const RegistrationsDesktop = (props) => {
  const { data: tickets } = useGetRegistrationTickets();
  const [ticketFilter, setTicketFilter] = useState(null);
  const [division, setDivision] = useState(null);

  const { data } = useGetEventRegistrations(
    ticketFilter ? ticketFilter : null,
    division ? division.value : null
  );
  const { data: divisions } = useGetMembershipDivisions(
    ticketFilter && ticketFilter !== 'All Registrations' ? ticketFilter : null
  );

  const [currentRegistration, setCurrentRegistration] = useState(null);

  const [shouldDisplayUpdateModal, setShouldDisplayUpdateModal] =
    useState(false);
  const [shouldDisplayCheckInModal, setShouldDisplayCheckInModal] =
    useState(false);
  const [unCheck, setUncheck] = useState(false);

  const checkinRegistration = useCheckinRegistration();
  const unCheckRegistration = useUnCheckRegistration();

  async function onConfirmCheckIn() {
    const { id } = currentRegistration;
    return unCheck
      ? await unCheckRegistration(id)
      : await checkinRegistration(id);
  }

  const columns = [
    {
      label: 'Check In',
      key: 'check_in',
    },
    {
      label: 'Date',
      key: 'date',
    },
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Registration Name',
      key: 'registration_name',
    },
    {
      label: 'Purchaser Name',
      key: 'purchaser_name',
    },
    {
      label: 'Division',
      key: 'division',
    },
    {
      label: 'Total',
      key: 'total',
    },
  ];

  function renderRows(rowData) {
    const { data, is_checked, purchase_date, registration, user, division } =
      rowData;

    const driverName = getDriverName(data, user);
    const { name, price } = registration;
    return {
      check_in: (
        <Checkbox
          aria-checked={!!is_checked}
          checked={!!is_checked}
          inversed
          name="check_in"
          onChange={() => {
            setCurrentRegistration(rowData);
            setUncheck(!!is_checked);
            setShouldDisplayCheckInModal(true);
          }}
          role="checkbox"
          tabIndex={0}
        />
      ),
      date: purchase_date,
      name: (
        <LineHeightText>
          <ParticipantName
            onClick={() => {
              setCurrentRegistration(rowData);
              setShouldDisplayUpdateModal(true);
            }}
          >
            {driverName}
          </ParticipantName>
        </LineHeightText>
      ),
      purchaser_name: user ? `${user.last_name}, ${user.first_name}` : '',
      registration_name: name,
      division: division,
      total: price ?? '',
    };
  }

  if (!data?.getEvent && !data?.getEventRegistrations) return null;

  return (
    <>
      <SearchableListContainer
        header={
          <RegistrationsHeader
            {...props}
            total={data?.getEventRegistrations.count}
            ticket_id={ticketFilter ? ticketFilter : null}
            division={division ? division.value : null}
            data={data}
            registration_type={
              ticketFilter
                ? tickets?.getRegistrationTickets.find(
                    (tix) => tix.id === Number(ticketFilter)
                  )
                : null
            }
          />
        }
        pageCount={data?.getEventRegistrations.count}
        paginated
        title={`Registrations (${data?.getEventRegistrations.count})`}
        searchInputPlaceholder="Search Registrations"
        inputMaxWidth="200px"
        titleBarContent={
          <TitleContainer>
            <MembershipFilterContainer>
              <Select
                placeholder="All Registrations"
                options={tickets.getRegistrationTickets.map((ticket) => ({
                  label: ticket.name,
                  value: ticket.id,
                }))}
                value={
                  ticketFilter
                    ? ticketFilter
                    : { label: 'All Registrations', value: null }
                }
                isSearchable
                isClearable
                onChange={({ target }) => {
                  setTicketFilter(target.value ?? null);
                }}
              />
            </MembershipFilterContainer>
            <Spacer size={5} />

            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                margin: 'auto',
              }}
            >
              {ticketFilter &&
              divisions &&
              divisions.getMembershipDivisions &&
              divisions.getMembershipDivisions.length > 0 ? (
                <FilterContainer>
                  <AutoSuggest
                    placeholder="Select Division"
                    options={divisions.getMembershipDivisions.map((ticket) => ({
                      label: ticket,
                      value: ticket,
                    }))}
                    value={division ? division : null}
                    isSearchable
                    isClearable
                    onChange={(ticket) => {
                      setDivision(ticket ?? null);
                    }}
                  />
                </FilterContainer>
              ) : null}
            </div>
          </TitleContainer>
        }
      >
        <Table
          items={data?.getEventRegistrations.results}
          columns={columns}
          renderRows={renderRows}
        />
      </SearchableListContainer>
      <UpdateRegistration
        currentTransaction={currentRegistration}
        close={() => {
          setShouldDisplayUpdateModal(false);
          setCurrentRegistration(null);
        }}
        isVisible={shouldDisplayUpdateModal}
      />
      <CheckInModal
        itemType="guest"
        isVisible={shouldDisplayCheckInModal}
        onConfirm={onConfirmCheckIn}
        setIsVisible={setShouldDisplayCheckInModal}
        unCheck={unCheck}
      />
    </>
  );
};
