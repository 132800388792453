import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_EVENTS_BY_ACCOUNT_ADMIN = gql`
  query GetEventsByAccountAdmin($input: Int) {
    getEventsByAccountAdmin(account_id: $input) {
      count
      results {
        id
        name
        start_date
        isMultiDay
        end_date
      }
    }
  }
`;

export function useGetEventsByAccountAdmin(account_id) {
  const variables = {
    input: account_id ? Number(account_id) : null,
  };

  return useQuery(GET_EVENTS_BY_ACCOUNT_ADMIN, { variables });
}
