import React, { useEffect } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { TitleContainer } from 'pages/track/Contact/style';
import { useMembership } from 'shared/membershipContext';
import FormsRepeater from './Components/FormsRepeater';
import Membership from './Components/Membership';
import UserDetail from './Components/UserDetail';
import Waiver from './Components/Waiver';
import { useGetMember } from './gql/useGetMember';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
  grid-auto-rows: 100px;
  grid-template-areas:
    'a a b b c c d d'
    'a a b b c c d d';
  align-items: start;
  padding: 30px;

  @media (max-width: 768px) {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

export const GridItem = styled.div`
  text-align: left;
  padding: 10px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const MemberDetail = () => {
  const { data, loading, refetch } = useGetMember();
  const history = useHistory();

  const { fetchMember, setFetchMember } = useMembership();

  useEffect(() => {
    if (fetchMember) {
      refetch();
      setFetchMember(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMember]);

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const { id } = useParams();

  if (!data) return <Loading />;

  const transferMember = () => {
    const oldUser = data && data.getMember?.user;

    return history.push(
      userType === 'admin'
        ? `/admin/transferMembership/${id}?membership=${
            data.getMember?.membership?.name
          }&oldUserName=${
            data.getMember.user &&
            `${oldUser.last_name ? `${oldUser.last_name}, ` : ''}${
              oldUser.first_name
            } ${oldUser.middle_name ? oldUser.middle_name : ''} ${
              oldUser.suffix ? `, ${oldUser.suffix}` : ''
            }`
          }`
        : `/admin-${userType}/transferMembership/${id}?membership=${
            data.getMember?.membership?.name
          }&oldUserName=${
            data.getMember.user &&
            `${oldUser.last_name ? `${oldUser.last_name}, ` : ''}${
              oldUser.first_name
            } ${oldUser.middle_name ? oldUser.middle_name : ''} ${
              oldUser.suffix ? `, ${oldUser.suffix}` : ''
            }`
          }`
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <ContainerHeader>
        <TitleContainer>
          <Text
            type="heading"
            color="#3C4144"
            inlineStyle={{ marginRight: 25 }}
          >
            Member Detail
          </Text>
        </TitleContainer>
        <div style={{ marginRight: 20 }}>
          <Button onClick={transferMember}>Transfer</Button>
        </div>
      </ContainerHeader>
      <Membership data={data.getMember} />

      <ContainerHeader>
        <TitleContainer>
          <Text
            type="heading"
            color="#3C4144"
            inlineStyle={{ marginRight: 25 }}
          >
            Pit Pay User Info
          </Text>
        </TitleContainer>
      </ContainerHeader>
      <UserDetail data={data.getMember.user} />

      {data.getMember.forms && data.getMember.forms.length ? (
        <ContainerHeader>
          <TitleContainer>
            <Text
              type="heading"
              color="#3C4144"
              inlineStyle={{ marginRight: 25 }}
            >
              Forms
            </Text>
          </TitleContainer>
        </ContainerHeader>
      ) : null}
      {data.getMember.forms && data.getMember.forms.length
        ? data.getMember.forms.map((form) => (
            <FormsRepeater formData={form} key={form.id} />
          ))
        : null}

      {data.getMember.waivers && data.getMember.waivers.length ? (
        <ContainerHeader>
          <TitleContainer>
            <Text
              type="heading"
              color="#3C4144"
              inlineStyle={{ marginRight: 25 }}
            >
              Agreements
            </Text>
          </TitleContainer>
        </ContainerHeader>
      ) : null}
      <Grid>
        {data.getMember.waivers && data.getMember.waivers.length
          ? data.getMember.waivers.map((waiver) => (
              <GridItem key={waiver.id}>
                <Waiver waiver={waiver} />
              </GridItem>
            ))
          : null}
      </Grid>
    </Container>
  );
};

export default MemberDetail;
