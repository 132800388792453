/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import { Button, RemoveButton } from 'components/Button';
import { DatePicker } from 'components/Form/DatePicker';
import {
  DraggableRow,
  FormWrapper,
  getHighestItemOrderNumber,
  getOrderedItems,
} from 'components/Form/DraggableFields';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import { ErrorText } from 'components/Form/styles';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { DESCRIPTION_CHARACTER_LIMIT } from './index';
import {
  ResponsiveCol,
  ResponsiveRow,
  SectionTitle,
} from '../AddEvents/styles';
import { AdminTickets } from '../tickets/AdminTickets';
import { MultidayTickets } from '../tickets/MultidayTickets';
import { OtherTickets } from '../tickets/OtherTickets';
import { BundleTickets } from '../tickets/BundleTickets';
import { useMediaQuery } from 'react-responsive';
import RegistrationTickets from '../tickets/RegistrationTickets';
import moment from 'moment';
import { useGetAllCategories } from '../gql/queries/useGetAllCategories';

const formatTimestamp = (timeStamp) => {
  const time = moment(timeStamp, 'HH:mm:ss').format('h:mm A');

  return `${time} EST`;
};

export const COLOR_CODES = [
  { label: 'Red', value: '#FF0000' },
  { label: 'Burgundy', value: '#950700' },
  { label: 'Magenta', value: '#FF0067' },
  { label: 'Black', value: '#000000' },
  { label: 'Gold', value: '#887A13' },
  { label: 'Baby Blue', value: '#00BBFF' },
  { label: 'Sky Blue', value: '#00F5FF' },
  { label: 'Blue', value: '#0034FF' },
  { label: 'Navy Blue', value: '#150067' },
  { label: 'Purple', value: '#580085' },
  { label: 'Light Purple', value: '#A743FF' },
  { label: 'School Bus Yellow', value: '#FCB92A' },
  { label: 'Bright Yellow', value: '#FDFA00' },
  { label: 'Yellow', value: '#FCE603' },
  { label: 'Brown', value: '#501D1D' },
  { label: 'Charcoal', value: '#5F534D' },
  { label: 'Rust Red', value: '#A04010' },
  { label: 'Pit Pay Orange', value: '#fa4616' },
  { label: 'Pit Pay Dark Blue', value: '#00001f' },
  { label: 'Avocado Green', value: '#90A010' },
  { label: 'Green Grass', value: '#00A526' },
  { label: 'Deere Green', value: '#367C2B' },
  { label: 'Flo Green', value: '#00ff00' },
  { label: 'Flo Pink', value: '#FF10F0' },
  { label: 'Bright Purple', value: '#8A2BE2' },
  { label: 'Bright Blue', value: '#097DF3' },
  { label: 'Flo Yellow', value: '#DEFF0A' },
  { label: 'Flo Orange', value: '#FF6700' },
  { label: 'Deep Red', value: '#CE2029' },
  { label: 'Bronze', value: '#6C541E' },
  { label: 'Silver', value: '#838996' },
  { label: 'Fuschia', value: '#D9004C' },
  { label: 'Burnt Orange', value: '#CC5500' },
  { label: 'Lime Green ', value: '#AEFF00' },
  { label: 'Turquoise', value: '#00ADA7' },
  { label: 'Mint Green ', value: '#43EE9E' },
  { label: 'Pink', value: '#FF8BFE' },
  { label: 'Plum Purple', value: '#620961' },
  { label: 'Grey', value: '#A7A6A7' },
];

const TICKET_TYPES = [
  { label: 'Tickets', value: 'ticket' },
  { label: 'Other Tickets', value: 'other' },
];

const REG_TICKET_TYPES = [{ label: 'Register Tickets', value: 'registration' }];

const TicketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const TicketCollapse = styled.div`
  &.collapsed {
    display: none;
  }
`;

export const InputWrapper = styled.div`
  margin-top: 10px;
  background-color: white;
  border: solid 1px #dcdcdc;
  border-radius: 5px;
`;

export const OrderText = styled.p`
  max-width: 95%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5em;
  ${'' /* margin: 15px; */}
`;

export const OrderSalesWindow = styled.p`
  max-width: 95%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-size: 12px;
  line-height: 1.5em;
  ${'' /* margin: 15px; */}
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Label = styled.label.attrs((props) => ({
  style: props.inlineStyle,
}))`
  color: black;
  font-weight: 500;
  margin-bottom: 6px;
  font-size: 4.2vw;
  display: block;

  @media (min-width: 700px) {
    font-size: 16px;
  }
`;

const CharacterCount = styled.span`
  color: ${(props) => (props.overLimit ? props.theme.colors.error : 'unset')};
  margin-bottom: 6px;
`;
CharacterCount.propTypes = {
  overLimit: PropTypes.bool,
};

const Ticket = ({
  handleChange,
  ticket,
  index,
  tickets,
  ticketType,
  handleDelete,
  collapsed,
  errors,
  allCategories,
}) => {
  return (
    <>
      {collapsed && <Text type="label">{ticket.name || 'New ticket'}</Text>}
      <TicketCollapse className={collapsed ? 'collapsed' : null}>
        <RemoveButton
          style={{ marginBottom: 20, marginTop: 40 }}
          onClick={handleDelete}
        />
        {ticketType === 'admin_tickets' ? (
          <AdminTickets
            COLOR_CODES={COLOR_CODES}
            handleChange={handleChange}
            ticket={ticket}
            index={index}
            ticketType={ticketType}
            handleDelete={handleDelete}
            collapsed={collapsed}
            errors={errors}
            allCategories={allCategories}
          />
        ) : ticketType === 'admin_multiday_tickets' ? (
          <MultidayTickets
            COLOR_CODES={COLOR_CODES}
            handleChange={handleChange}
            ticket={ticket}
            index={index}
            ticketType={ticketType}
            handleDelete={handleDelete}
            collapsed={collapsed}
            errors={errors}
            allCategories={allCategories}
          />
        ) : ticketType === 'admin_other_tickets' ? (
          <OtherTickets
            COLOR_CODES={COLOR_CODES}
            handleChange={handleChange}
            ticket={ticket}
            index={index}
            ticketType={ticketType}
            handleDelete={handleDelete}
            collapsed={collapsed}
            errors={errors}
            allCategories={allCategories}
          />
        ) : ticketType === 'admin_bundle_tickets' ? (
          process.env.REACT_APP_PLATFORM === 'tickethoss' && (
            <BundleTickets
              COLOR_CODES={COLOR_CODES}
              handleChange={handleChange}
              ticket={ticket}
              tickets={tickets}
              index={index}
              ticketType={ticketType}
              handleDelete={handleDelete}
              collapsed={collapsed}
              errors={errors}
              allCategories={allCategories}
            />
          )
        ) : (
          process.env.REACT_APP_PLATFORM !== 'tickethoss' && (
            <RegistrationTickets
              COLOR_CODES={COLOR_CODES}
              handleChange={handleChange}
              ticket={ticket}
              index={index}
              ticketType={ticketType}
              handleDelete={handleDelete}
              collapsed={collapsed}
              errors={errors}
              allCategories={allCategories}
            />
          )
        )}
        <Spacer size={20} />
      </TicketCollapse>
    </>
  );
};

export const EditEventTickets = ({
  tickets,
  onChange,
  handleSort,
  customPadding,
  errors,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [ticketType, setTicketType] = useState('');
  const ticketTypeName = ticketType.split('_').slice(1).join(' ');

  const { data, loading } = useGetAllCategories();
  const [categoryMap, setCategoryMap] = useState();
  const [categoryOptions, setCategoryOptions] = useState();

  useEffect(() => {
    if (data && data.getAllCategories) {
      const categoryOptions = data.getAllCategories.map((category) => ({
        label: category.name,
        value: category.id,
      }));

      const categoryMap = data.getAllCategories.reduce((acc, category) => {
        return { ...acc, [category.id]: category.name };
      }, {});

      setCategoryOptions(categoryOptions);
      setCategoryMap(categoryMap);
    }
  }, [data, loading]);

  const combinedTicketsWithOrderNumbers = getOrderedItems(
    tickets.admin_ticket_view || []
  );

  const singleTicketsWithOrderNumbers = getOrderedItems(
    tickets.admin_tickets || []
  );
  const multiTicketsWithOrderNumbers = getOrderedItems(
    tickets.admin_multiday_tickets || []
  );
  const otherTicketsWithOrderNumbers = getOrderedItems(
    tickets.admin_other_tickets || []
  );
  const otherTicketsViewWithOrderNumbers = getOrderedItems(
    tickets.admin_other_view || []
  );
  const bundleTicketsWithOrderNumbers = getOrderedItems(
    tickets.admin_bundle_tickets || []
  );

  // Create an array of ticket with order numbers from the map
  const combinedTicketsWithOrderNumbersArray = tickets.admin_ticket_view_key
    ? tickets.admin_ticket_view_key.reduce((acc, key) => {
        if (tickets[key] && tickets[key].length > 0)
          return acc.concat([getOrderedItems(tickets[key])]);
      }, [])
    : [];

  const handleCategoryChange = (target, ticket) => {
    if (target.name === 'category_id') {
      // Check and add it to a category list if it exists or create a new one if not
      const newKey = target.value.toString();
      let addToCategory,
        newKeys = tickets.admin_ticket_view_key;

      if (tickets[newKey] && tickets[newKey].length > 0) {
        addToCategory = tickets[newKey].concat([
          { ...ticket, category_id: target.value },
        ]);
      } else {
        addToCategory = [{ ...ticket, category_id: target.value }];

        newKeys = [...tickets.admin_ticket_view_key, newKey];

        // Remove it from deleted list
        if (tickets.deleted_keys) {
          const deletedKeys = tickets.deleted_keys.filter(
            (key) => key !== newKey
          );
          onChange('deleted_keys', deletedKeys);
        }
      }

      // Remove it from previous category list
      if (ticket.category_id || ticket.category_id === 0) {
        const removeFromCategory = tickets[
          ticket.category_id.toString()
        ].filter((categoryTicket) => categoryTicket.id !== ticket.id);

        if (removeFromCategory.length === 0) {
          // Remove it from the key list
          newKeys = newKeys.filter(
            (key) => key !== ticket.category_id.toString()
          );

          // Add the key to deleted list
          const deletedKeys = tickets.deleted_keys
            ? tickets.deleted_keys.concat(ticket.category_id.toString())
            : [ticket.category_id.toString()];

          onChange('deleted_keys', deletedKeys);
        }

        onChange(ticket.category_id.toString(), removeFromCategory);
      } else {
        const removeFromCategory = tickets['Unassigned'].filter(
          (categoryTicket) => categoryTicket.id !== ticket.id
        );

        if (removeFromCategory.length === 0) {
          // Remove it from the key list
          newKeys = newKeys.filter((key) => key !== 'Unassigned');
        }

        onChange('Unassigned', removeFromCategory);
      }

      onChange('admin_ticket_view_key', newKeys);
      onChange(newKey, addToCategory);
    } else {
      // Update the ticket value in the list
      const newTickets = tickets[
        ticket.category_id ? ticket.category_id : 'Unassigned'
      ].map((categoryTicket) =>
        categoryTicket.order === ticket.order
          ? {
              ...categoryTicket,
              [target.name]: target.value,
              ...(target.name === 'is_featured' && !target.value
                ? { featured_text: null }
                : null),
            }
          : categoryTicket
      );

      onChange(
        ticket.category_id ? ticket.category_id.toString() : 'Unassigned',
        newTickets
      );
    }
  };

  const handleDeleteCategory = (ticket) => {
    if (ticket.category_id || ticket.category_id === 0) {
      const removeFromCategory = tickets[ticket.category_id.toString()].filter(
        (categoryTicket) => categoryTicket.id !== ticket.id
      );

      if (removeFromCategory.length === 0) {
        // Remove it from the key list
        const newKeys = tickets.admin_ticket_view_key.filter(
          (key) => key !== ticket.category_id.toString()
        );

        // Add the key to deleted list
        const deletedKeys = tickets.deleted_keys
          ? tickets.deleted_keys.concat(ticket.category_id.toString())
          : [ticket.category_id.toString()];

        onChange('deleted_keys', deletedKeys);

        onChange('admin_ticket_view_key', newKeys);
      }

      onChange(ticket.category_id.toString(), removeFromCategory);
    } else {
      const removeFromCategory = tickets['Unassigned'].filter(
        (categoryTicket) => categoryTicket.id !== ticket.id
      );

      if (removeFromCategory.length === 0) {
        // Remove it from the key list
        const newKeys = tickets.admin_ticket_view_key.filter(
          (key) => key !== 'Unassigned'
        );

        onChange('admin_ticket_view_key', newKeys);
      }

      onChange('Unassigned', removeFromCategory);
    }
  };

  const addNewTicketToAdmin = (isMultiDay) => {
    // Add to unassigned category
    const indexOfUnassigned = tickets.admin_ticket_view_key
      ? tickets.admin_ticket_view_key.indexOf('Unassigned')
      : -1;

    const order =
      getHighestItemOrderNumber(
        combinedTicketsWithOrderNumbersArray[indexOfUnassigned]
          ? combinedTicketsWithOrderNumbersArray[indexOfUnassigned]
          : combinedTicketsWithOrderNumbers
      ) + 1;

    const newTicket = {
      order: order,
      type: 'admin',
      isMultiDay: isMultiDay,
      start_date: tickets.start_date,
    };

    if (isMultiDay)
      onChange('admin_multiday_tickets', [
        ...tickets.admin_multiday_tickets,
        newTicket,
      ]);
    else onChange('admin_tickets', [...tickets.admin_tickets, newTicket]);

    onChange('admin_ticket_view', [...tickets.admin_ticket_view, newTicket]);

    if (indexOfUnassigned !== -1) {
      onChange('Unassigned', [
        ...tickets['Unassigned'],
        {
          ...newTicket,
          category_id: 'Unassigned',
        },
      ]);
    } else {
      onChange('admin_ticket_view_key', [
        ...tickets.admin_ticket_view_key,
        'Unassigned',
      ]);
      onChange('Unassigned', [
        {
          ...newTicket,
          category_id: 'Unassigned',
        },
      ]);
    }
  };

  return (
    <Col>
      <ResponsiveRow>
        <Button
          type="button"
          buttonStyle={{
            margin: '10px',
          }}
          onClick={() => {
            addNewTicketToAdmin(false);
          }}
        >
          singleDay
        </Button>
        <Button
          type="button"
          buttonStyle={{
            margin: '10px',
          }}
          onClick={() => {
            addNewTicketToAdmin(true);
          }}
        >
          MultiDay
        </Button>
        <Button
          type="button"
          buttonStyle={{
            margin: '10px',
          }}
          onClick={() => {
            // Add to unassigned category
            const indexOfUnassigned = tickets.admin_ticket_view_key
              ? tickets.admin_ticket_view_key.indexOf('Unassigned')
              : -1;

            const order =
              getHighestItemOrderNumber(
                combinedTicketsWithOrderNumbersArray[indexOfUnassigned]
                  ? combinedTicketsWithOrderNumbersArray[indexOfUnassigned]
                  : combinedTicketsWithOrderNumbers
              ) + 1;

            onChange('admin_other_tickets', [
              ...tickets.admin_other_tickets,
              {
                name: null,
                order: order,
                type: 'other',
              },
            ]);
            if (process.env.REACT_APP_PLATFORM === 'tickethoss')
              onChange('admin_ticket_view', [
                ...tickets.admin_ticket_view,
                {
                  order: order,
                  type: 'other',
                },
              ]);
            else
              onChange('admin_other_view', [
                ...tickets.admin_other_view,
                {
                  order: order,
                  type: 'other',
                },
              ]);

            if (indexOfUnassigned !== -1) {
              onChange('Unassigned', [
                ...tickets['Unassigned'],
                {
                  order: order,
                  type: 'other',
                  category_id: 'Unassigned',
                },
              ]);
            } else {
              onChange('admin_ticket_view_key', [
                ...tickets.admin_ticket_view_key,
                'Unassigned',
              ]);
              onChange('Unassigned', [
                {
                  order: order,
                  type: 'other',
                  category_id: 'Unassigned',
                },
              ]);
            }
          }}
        >
          Other
        </Button>
        {process.env.REACT_APP_PLATFORM === 'tickethoss' && (
          <Button
            type="button"
            buttonStyle={{
              margin: '10px',
            }}
            onClick={() => {
              // Add to unassigned category
              const indexOfUnassigned = tickets.admin_ticket_view_key
                ? tickets.admin_ticket_view_key.indexOf('Unassigned')
                : -1;

              const order =
                getHighestItemOrderNumber(
                  combinedTicketsWithOrderNumbersArray[indexOfUnassigned]
                    ? combinedTicketsWithOrderNumbersArray[indexOfUnassigned]
                    : combinedTicketsWithOrderNumbers
                ) + 1;

              const associatedTickets = [
                ...tickets.admin_tickets,
                ...tickets.admin_multiday_tickets,
                ...tickets.admin_other_tickets,
              ].map((ticket, index) => ({
                name: ticket.name,
                id: ticket.id ? ticket.id : index,
              }));

              onChange('admin_bundle_tickets', [
                ...tickets.admin_bundle_tickets,
                {
                  name: null,
                  order: order,
                  type: 'bundle',
                  associated_tickets: associatedTickets,
                },
              ]);
              onChange('admin_ticket_view', [
                ...tickets.admin_ticket_view,
                {
                  order: order,
                  type: 'bundle',
                  associated_tickets: associatedTickets,
                },
              ]);

              if (indexOfUnassigned !== -1) {
                onChange('Unassigned', [
                  ...tickets['Unassigned'],
                  {
                    order: order,
                    type: 'bundle',
                    associated_tickets: [{ name: '', quantity: '' }],
                    category_id: 'Unassigned',
                  },
                ]);
              } else {
                onChange('admin_ticket_view_key', [
                  ...tickets.admin_ticket_view_key,
                  'Unassigned',
                ]);
                onChange('Unassigned', [
                  {
                    order: order,
                    type: 'bundle',
                    associated_tickets: [{ name: '', quantity: '' }],
                    category_id: 'Unassigned',
                  },
                ]);
              }
            }}
          >
            Bundle
          </Button>
        )}
      </ResponsiveRow>
      <ResponsiveRow>
        <ResponsiveCol>
          <div>
            {tickets.admin_tickets && (
              <div>
                {tickets.admin_tickets.map((ticket, index) => (
                  <div key={`${index}-${ticket?.category_id}`}>
                    <FormWrapper>
                      <Ticket
                        ticket={ticket}
                        ticketType={'admin_tickets'}
                        index={index}
                        allCategories={categoryOptions}
                        handleDelete={() => {
                          const newTickets = tickets.admin_tickets.filter(
                            (_, currentIndex) => currentIndex !== index
                          );
                          const newTicketView =
                            tickets.admin_ticket_view.filter(
                              (ticketView) => ticketView.order !== ticket.order
                            );
                          handleDeleteCategory(ticket);

                          onChange('admin_tickets', newTickets);
                          onChange('admin_ticket_view', newTicketView);
                        }}
                        handleChange={({ target }) => {
                          const newTickets = tickets.admin_tickets.map(
                            (ticket, ticketIndex) =>
                              ticketIndex === index
                                ? {
                                    ...ticket,
                                    [target.name]: target.value,
                                    ...(target.name === 'is_featured' &&
                                    !target.value
                                      ? { featured_text: null }
                                      : null),
                                  }
                                : ticket
                          );
                          const newTicketView = tickets.admin_ticket_view.map(
                            (ticketView) =>
                              ticketView.order === ticket.order &&
                              ticketView.category_id === ticket.category_id
                                ? {
                                    ...ticket,
                                    [target.name]: target.value,
                                    ...(target.name === 'is_featured' &&
                                    !target.value
                                      ? { featured_text: null }
                                      : null),
                                  }
                                : ticketView
                          );

                          onChange('admin_tickets', newTickets);
                          onChange('admin_ticket_view', newTicketView);

                          handleCategoryChange(target, ticket);
                        }}
                        collapsed={isCollapsed}
                        errors={errors}
                      />
                    </FormWrapper>
                  </div>
                ))}
              </div>
            )}
            {tickets.admin_multiday_tickets && (
              <div>
                {tickets.admin_multiday_tickets.map((ticket, index) => (
                  <div key={`${index}-${ticket?.category_id}`}>
                    <FormWrapper>
                      <Ticket
                        ticket={ticket}
                        ticketType={'admin_multiday_tickets'}
                        index={index}
                        allCategories={categoryOptions}
                        handleDelete={() => {
                          const newTickets =
                            tickets.admin_multiday_tickets.filter(
                              (_, currentIndex) => currentIndex !== index
                            );
                          const newTicketView =
                            tickets.admin_ticket_view.filter(
                              (ticketView) => ticketView.order !== ticket.order
                            );

                          handleDeleteCategory(ticket);

                          onChange('admin_multiday_tickets', newTickets);
                          onChange('admin_ticket_view', newTicketView);
                        }}
                        handleChange={({ target }) => {
                          const newTickets = tickets.admin_multiday_tickets.map(
                            (ticket, ticketIndex) =>
                              ticketIndex === index
                                ? { ...ticket, [target.name]: target.value }
                                : ticket
                          );
                          const newTicketView = tickets.admin_ticket_view.map(
                            (ticketView) =>
                              ticketView.order === ticket.order &&
                              ticketView.category_id === ticket.category_id
                                ? { ...ticket, [target.name]: target.value }
                                : ticketView
                          );
                          onChange('admin_multiday_tickets', newTickets);
                          onChange('admin_ticket_view', newTicketView);

                          handleCategoryChange(target, ticket);
                        }}
                        collapsed={isCollapsed}
                        errors={errors}
                      />
                    </FormWrapper>
                  </div>
                ))}
              </div>
            )}
            {otherTicketsWithOrderNumbers.length > 0 && (
              <div>
                {otherTicketsWithOrderNumbers.map((ticket, index) => (
                  <div key={`${index}-${ticket?.category_id}`}>
                    <FormWrapper>
                      <Ticket
                        ticket={ticket}
                        ticketType={'admin_other_tickets'}
                        index={index}
                        allCategories={categoryOptions}
                        handleDelete={() => {
                          const newTickets = tickets.admin_other_tickets.filter(
                            (_, currentIndex) => currentIndex !== index
                          );
                          const newTicketView =
                            tickets.admin_ticket_view.filter(
                              (ticketView) => ticketView.order !== ticket.order
                            );
                          const newTicketView2 =
                            tickets.admin_other_view.filter(
                              (ticketView) => ticketView.order !== ticket.order
                            );
                          handleDeleteCategory(ticket);

                          onChange('admin_other_tickets', newTickets);
                          if (process.env.REACT_APP_PLATFORM === 'tickethoss')
                            onChange('admin_ticket_view', newTicketView);
                          else onChange('admin_other_view', newTicketView2);
                        }}
                        handleChange={({ target }) => {
                          const newTickets = tickets.admin_other_tickets.map(
                            (ticket, ticketIndex) =>
                              ticketIndex === index
                                ? { ...ticket, [target.name]: target.value }
                                : ticket
                          );
                          const newTicketView = tickets.admin_ticket_view.map(
                            (ticketView) =>
                              ticketView.order === ticket.order &&
                              ticketView.category_id === ticket.category_id
                                ? { ...ticket, [target.name]: target.value }
                                : ticketView
                          );
                          const newTicketView2 = tickets.admin_other_view.map(
                            (ticketView) =>
                              ticketView.order === ticket.order &&
                              ticketView.category_id === ticket.category_id
                                ? { ...ticket, [target.name]: target.value }
                                : ticketView
                          );
                          onChange('admin_other_tickets', newTickets);
                          if (process.env.REACT_APP_PLATFORM === 'tickethoss')
                            onChange('admin_ticket_view', newTicketView);
                          else onChange('admin_other_view', newTicketView2);

                          handleCategoryChange(target, ticket);
                        }}
                        collapsed={isCollapsed}
                        errors={errors}
                      />
                    </FormWrapper>
                  </div>
                ))}
              </div>
            )}
            {bundleTicketsWithOrderNumbers.length > 0 &&
              process.env.REACT_APP_PLATFORM === 'tickethoss' && (
                <div>
                  {bundleTicketsWithOrderNumbers.map((ticket, index) => (
                    <div key={`${index}-${ticket?.category_id}`}>
                      <FormWrapper>
                        <Ticket
                          ticket={ticket}
                          ticketType={'admin_bundle_tickets'}
                          tickets={tickets}
                          index={index}
                          allCategories={categoryOptions}
                          handleDelete={() => {
                            const newTickets =
                              tickets.admin_bundle_tickets.filter(
                                (_, currentIndex) => currentIndex !== index
                              );
                            const newTicketView =
                              tickets.admin_ticket_view.filter(
                                (ticketView) =>
                                  ticketView.order !== ticket.order
                              );
                            handleDeleteCategory(ticket);

                            onChange('admin_bundle_tickets', newTickets);
                            onChange('admin_ticket_view', newTicketView);
                          }}
                          handleChange={({ target }) => {
                            const newTickets = tickets.admin_bundle_tickets.map(
                              (ticket, ticketIndex) =>
                                ticketIndex === index
                                  ? { ...ticket, [target.name]: target.value }
                                  : ticket
                            );
                            const newTicketView = tickets.admin_ticket_view.map(
                              (ticketView) =>
                                ticketView.order === ticket.order &&
                                ticketView.category_id === ticket.category_id
                                  ? { ...ticket, [target.name]: target.value }
                                  : ticketView
                            );
                            onChange('admin_bundle_tickets', newTickets);
                            onChange('admin_ticket_view', newTicketView);

                            handleCategoryChange(target, ticket);
                          }}
                          collapsed={isCollapsed}
                          errors={errors}
                        />
                      </FormWrapper>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </ResponsiveCol>
        <ResponsiveCol
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: isMobile ? 0 : '60px',
            paddingTop: '50px',
            flex: '0.5',
          }}
        >
          {tickets.admin_ticket_view?.length > 0 && (
            <ResponsiveCol>
              {combinedTicketsWithOrderNumbersArray.map(
                (combinedTicketsWithOrderNumbers) => {
                  const category_id = combinedTicketsWithOrderNumbers[0]
                    ?.category_id
                    ? combinedTicketsWithOrderNumbers[0].category_id
                    : 'Unassigned';

                  return (
                    <>
                      <LabelWrapper style={{ marginLeft: -20 }}>
                        <Label>
                          {category_id !== 'Unassigned'
                            ? categoryMap[category_id]
                            : 'Unassigned'}
                        </Label>
                      </LabelWrapper>
                      <ReactSortable
                        list={combinedTicketsWithOrderNumbers}
                        setList={(values) => handleSort(category_id, values)}
                      >
                        {combinedTicketsWithOrderNumbers.map((ticket) => (
                          <DraggableRow
                            key={`${ticket.order}-${ticket?.category_id}`}
                          >
                            <InputWrapper
                              style={{
                                width: '100%',
                                borderRight: `15px solid ${ticket.color_code}`,
                                padding: '15px',
                              }}
                            >
                              <div>
                                <OrderText>
                                  {ticket.name} -{' '}
                                  <span style={{ fontFamily: 'Roboto' }}>
                                    {' '}
                                    ${ticket.price}
                                    {ticket.start_date
                                      ? ` - ${moment(ticket.start_date).format(
                                          'MM/DD/YYYY'
                                        )}`
                                      : ''}
                                    {ticket.end_date
                                      ? ` - ${moment(ticket.end_date).format(
                                          'MM/DD/YYYY'
                                        )}`
                                      : ''}
                                  </span>
                                </OrderText>
                                {ticket.sale_start || ticket.sale_end ? (
                                  <>
                                    <OrderSalesWindow>
                                      <span style={{ fontWeight: 'bold' }}>
                                        Start:
                                      </span>{' '}
                                      <span>
                                        {ticket.sale_start
                                          ? moment(ticket.sale_start).format(
                                              'MM/DD/YYYY'
                                            )
                                          : ''}{' '}
                                        {ticket.on_sale_time
                                          ? formatTimestamp(ticket.on_sale_time)
                                          : ticket.sale_start
                                          ? formatTimestamp('00:00:00')
                                          : ''}
                                      </span>{' '}
                                    </OrderSalesWindow>
                                    <OrderSalesWindow>
                                      <span style={{ fontWeight: 'bold' }}>
                                        End:
                                      </span>{' '}
                                      <span>
                                        {ticket.sale_end
                                          ? moment(ticket.sale_end).format(
                                              'MM/DD/YYYY'
                                            )
                                          : ''}
                                        :{': '}
                                        {ticket.off_sale_time
                                          ? formatTimestamp(
                                              ticket.off_sale_time
                                            )
                                          : ticket.sale_end
                                          ? formatTimestamp('23:59:00')
                                          : ''}
                                      </span>
                                    </OrderSalesWindow>
                                  </>
                                ) : null}
                              </div>
                            </InputWrapper>
                          </DraggableRow>
                        ))}
                      </ReactSortable>
                    </>
                  );
                }
              )}
            </ResponsiveCol>
          )}
        </ResponsiveCol>
      </ResponsiveRow>
    </Col>
  );
};

const ticketProp = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  color_code: PropTypes.string,
  description: PropTypes.string,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

const ticketTypeProp = PropTypes.oneOf([
  'admin_tickets',
  'admin_multiday_tickets',
  'admin_other_tickets',
  'admin_bundle_tickets',
]);

Ticket.propTypes = {
  ticket: ticketProp.isRequired,
  // ticketType: ticketTypeProp.isRequired,
  index: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

EditEventTickets.propTypes = {
  tickets: PropTypes.arrayOf(ticketProp).isRequired,
  // ticketType: ticketTypeProp.isRequired,
  onChange: PropTypes.func.isRequired,
  addButtonText: PropTypes.string.isRequired,
  error: PropTypes.string,
};
